const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const localizedFormat = require('dayjs/plugin/localizedFormat');

// Plugins registrieren
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

// Standardzeitzone
const DEFAULT_TIMEZONE = 'Europe/Berlin';

const DateTimeHelper = {
    /**
     * Formatiert ein Datum in ein benutzerfreundliches Format (Standard: DD.MM.YYYY HH:mm).
     * @param {string} dateString - Das Datumsstring, das formatiert werden soll.
     * @param {string} format - (Optional) Das gewünschte Format.
     * @param {string} timezone - (Optional) Die Zeitzone (Standard: Europe/Berlin).
     * @returns {string} - Das formatierte Datum.
     */
    formatDate(dateString, format = 'DD.MM.YYYY HH:mm', timezone = DEFAULT_TIMEZONE) {
        return dayjs.utc(dateString).tz(timezone).format(format);
    },

    /**
     * Gibt nur das Datum zurück (z. B. 17.01.2025).
     * @param {string} dateString - Das Datumsstring, das formatiert werden soll.
     * @param {string} timezone - (Optional) Die Zeitzone (Standard: Europe/Berlin).
     * @returns {string} - Das Datum im Format DD.MM.YYYY.
     */
    formatDateOnly(dateString, timezone = DEFAULT_TIMEZONE) {
        return dayjs.utc(dateString).tz(timezone).format('DD.MM.YYYY');
    },

    /**
     * Gibt nur die Zeit zurück (z. B. 06:19).
     * @param {string} dateString - Das Datumsstring, das formatiert werden soll.
     * @param {string} timezone - (Optional) Die Zeitzone (Standard: Europe/Berlin).
     * @returns {string} - Die Zeit im Format HH:mm.
     */
    formatTimeOnly(dateString, timezone = DEFAULT_TIMEZONE) {
        return dayjs.utc(dateString).tz(timezone).format('HH:mm');
    },

};

module.exports = DateTimeHelper;