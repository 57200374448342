import React, { useState } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Switch,
    Grid,
    Checkbox,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de'; // Stellen sicher, dass das deutsche Locale geladen wird
import { useUser } from '../components/userContext';
import TransportDesignBox from '../components/TransportDesignBox';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { getValidationErrors } from '../utils/validationLogic';
import { getTimeRangeText, getTimeRangeInfoText } from '../utils/whenStepHelpers';

const WhenExpertStep = ({ formData, setFormData, isExpertMode, isEditMode, timeSettings }) => {
    const [dateError, setDateError] = useState(null);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [timeErrors, setTimeErrors] = useState({});
    const theme = useTheme();

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleSaveDialog = () => {
        const hasTimeErrors = Object.values(timeErrors).some(error => !!error);

        if (!hasRecurringDays && !hasRecurringReturnDays) {
            alert('Bitte wählen Sie mindestens einen Tag aus.');
            return;
        }

        if (hasTimeErrors) {
            alert('Bitte korrigieren Sie die markierten Zeitangaben.');
            return;
        }

        setDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };



    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'transport_type' && { initial_trip: true }), // Füge initial_trip hinzu, wenn name === 'transport_type'
        }));
    };

    const now = dayjs();
    let minDate = dayjs().add(60, 'minutes');
    if (['poweruser', 'admin'].includes(user?.role)) {
        minDate = dayjs().add(-1, 'minutes');;
    }
    const maxDate = dayjs().add(10, 'years');

    dayjs.extend(updateLocale);
    // Replace "en" with the name of the locale you want to update.
    dayjs.updateLocale('de', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    const errorMessage = React.useMemo(() => {
        const isDepartureDateTimeValid = dayjs(formData.departureDateTime).isBetween(now, minDate, null, '[]'); // '[]' bedeutet inklusiv, d.h., die Grenzen sind eingeschlossen

        switch (dateError) {
            case 'maxDate':
            case 'minDate':
            case 'minDateTime':
                return 'Ungültiges Datum. Bitte prüfen...';

            case 'minTime':
                if (isDepartureDateTimeValid) {
                    return 'Abfahrstdatum zu knapp. Bitte bedenken Sie, dass wir etwas Vorlaufzeit benötigen.';
                } else {
                    return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';
                }

            case 'disablePast':
                return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';

            case 'invalidDate':
                return 'Datum nicht korrekt';

            default:
                return '';
        }
        // eslint-disable-next-line
    }, [dateError]);

    // Berechnet die Standard-Rückfahrtzeit basierend auf der Hinfahrtzeit + 4 Stunden
    const getDefaultReturnTime = () => {
        return formData.departureDateTime ? dayjs(formData.departureDateTime).add(4, 'hours') : null;
    };

    // Minimumzeit für die Rückfahrt (2 Stunden nach der Hinfahrt)
    const getMinReturnTime = () => {
        return minDate;
    };

    const handleRecurringChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            is_recurring: event.target.checked,
            departureDateTime: event.target.checked ? null : prevData.departureDateTime,
            returnDepartureTime: event.target.checked ? null : prevData.returnDepartureTime,
        }));
    };

    const handleInitalTripChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            initial_trip: event.target.checked,
        }));
    };

    const handleReturnTripChange = (event) => {
        const checked = event.target.checked;
        setFormData((prevData) => ({
            ...prevData,
            return_trip: checked,
            // Setzt die Standardzeit auf 4 Stunden nach der Hinfahrt, falls Rückfahrt aktiviert wird
            returnDepartureTime: checked ? getDefaultReturnTime() : null,
        }));
    };

    const handleDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_days: {
                ...prevData.recurring_days,
                [day]: event.target.checked,
            },
        }));
    };

    const handleReturnDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_return_days: {
                ...prevData.recurring_return_days,
                [day]: event.target.checked,
            },
        }));
    };

    const handleTimeChange = (day, time) => {
        // Überprüfen, ob eine gültige Uhrzeit eingegeben wurde
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_times: {
                    ...prevData.recurring_times,
                    [day]: time,
                },
            }));
        } else {
            // Gib eine Fehlermeldung oder Warnung aus, wenn keine gültige Uhrzeit eingegeben wurde
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    const handleReturnTimeChange = (day, time) => {
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_return_times: {
                    ...prevData.recurring_return_times,
                    [day]: time,
                },
            }));
        } else {
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    const getRecurringSummary = (days, times) => {
        const daysAndTimes = [];
        for (const [day, isSelected] of Object.entries(days || {})) {
            if (isSelected) {
                const time = times?.[day] || 'Keine Zeit angegeben';
                daysAndTimes.push(`${day}: ${time}`);
            }
        }
        return daysAndTimes.length > 0
            ? daysAndTimes.join(', ')
            : 'Keine Tage oder Zeiten ausgewählt';
    };

    // Zeit-Constraints für wiederkehrende Fahrten
    const getTimeConstraints = (transportType, isReturn = false) => {
        let settingKey = transportType;
        if (transportType === 'DIALYSEFAHRT') {
            settingKey = isReturn ? 'DIALYSE_RUECKFART' : 'DIALYSE_HINFART';
        }

        const settings = timeSettings?.settings?.[settingKey] || {};
        const minTime = settings.startTime ? dayjs(settings.startTime, 'HH:mm') : null;
        const maxTime = settings.endTime ? dayjs(settings.endTime, 'HH:mm') : null;

        return { minTime, maxTime };
    };

    // Zeitvalidierung für TimePicker
    const validateTime = (time, transportType, isReturn = false) => {
        const { minTime, maxTime } = getTimeConstraints(transportType, isReturn);
        const timeObj = dayjs(time, 'HH:mm');

        if (minTime && timeObj.isBefore(minTime)) {
            return `Zeit muss nach ${minTime.format('HH:mm')} liegen`;
        }

        if (maxTime && timeObj.isAfter(maxTime)) {
            return `Zeit muss vor ${maxTime.format('HH:mm')} liegen`;
        }

        return null;
    };

    const RestrictedTimePicker = ({ day, time, onChange, transportType, isReturn }) => {
        const { minTime, maxTime } = getTimeConstraints(transportType, isReturn);
        const errorKey = isReturn ? `rück-${day}` : `hin-${day}`;
        const error = timeErrors[errorKey] || '';
        const [rawValue, setRawValue] = useState(time ? dayjs(time, 'HH:mm') : null);

        const handleAccept = (acceptedValue) => {
            const timeString = acceptedValue ? acceptedValue.format('HH:mm') : '';
            const validationError = validateTime(timeString, transportType, isReturn);

            setTimeErrors(prev => ({ ...prev, [errorKey]: validationError || '' }));
            onChange(day, timeString);
            setRawValue(acceptedValue);
        };

        const handleChange = (newValue) => {
            // Temporärer Rohwert für die Anzeige
            setRawValue(newValue);
        };

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <TimePicker
                    desktopModeMediaQuery="@media (min-width: 0px)"
                    label={isReturn ? "Rückfahrtzeit" : "Uhrzeit"}
                    views={['hours', 'minutes']}
                    format="HH:mm"
                    value={rawValue}
                    onChange={handleChange}
                    onAccept={handleAccept}
                    minTime={minTime}
                    maxTime={maxTime}
                    shouldDisableTime={(timeValue, view) => {
                        if (view !== 'hours' && view !== 'minutes') return false;
                        return (minTime && timeValue.isBefore(minTime)) ||
                            (maxTime && timeValue.isAfter(maxTime));
                    }}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error,
                            InputLabelProps: { shrink: true },
                            onBlur: () => {
                                // Bei Verlassen des Feldes validieren
                                if (rawValue) handleAccept(rawValue);
                            }
                        },
                        inputAdornment: {
                            style: { marginRight: '8px' }
                        }
                    }}
                    disableMaskedInput // Wichtig für bessere Kontrolle
                />
            </LocalizationProvider>
        );
    };

    const noCheckboxChecked = !formData.initial_trip && !formData.return_trip;

    /** Hinweis: diese Logik gibt es auch im validationLogic.js für die gesamte Validierung der Formularfelder */
    // Neue Validierungslogik für wiederkehrende Fahrten
    const hasRecurringDays = formData.recurring_days && Object.values(formData.recurring_days).some(isSelected => isSelected === true);
    const hasRecurringReturnDays = formData.recurring_return_days && Object.values(formData.recurring_return_days).some(isSelected => isSelected === true);

    const recurringError =
        (formData.is_recurring && !hasRecurringDays) ||
        (formData.is_recurring_return && !hasRecurringReturnDays);

    const removeMarkdownFormatting = (text) => {
        return text.replace(/\*\*/g, '');
    };

    const recurringTimeErrors = getValidationErrors(2, formData, timeSettings, isEditMode, user)
        .map(error => removeMarkdownFormatting(error));

    const isRecurring = formData.is_recurring || formData.is_recurring_return || false;
    const isSeriesTerm = formData.is_recurring || formData.is_recurring_return;

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6">Wann soll der Transport durchgeführt werden?</Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    {/* */}
                    <FormLabel component="legend" style={{ color: formData.transport_type ? 'inherit' : '#E61F02' }}>
                        Art des Transports
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-label="transport_type"
                        name="transport_type"
                        value={formData.transport_type || ''}
                        onChange={handleChange}
                        disabled={isTransportApproved || formData.isHeadOfSeries || isSeriesTerm}
                    >
                        {['TERMINFAHRT', 'ENTLASSUNG', 'EINWEISUNG', 'VERLEGUNG', 'DIALYSEFAHRT'].map((type) => (
                            <FormControlLabel
                                key={type}
                                value={type}
                                style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }}
                                control={
                                    <Radio
                                        tabIndex={0} // Macht den Radio-Button fokussierbar
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleChange({ target: { name: 'transport_type', value: type } });
                                            }
                                        }}
                                        disabled={isTransportApproved || formData.isHeadOfSeries}
                                    />
                                }
                                label={
                                    type === 'TERMINFAHRT' ? 'Terminfahrt' :
                                        type === 'ENTLASSUNG' ? 'Entlassung' :
                                            type === 'EINWEISUNG' ? 'Einweisung' :
                                                type === 'VERLEGUNG' ? 'Verlegung' : 'Dialysefahrt'
                                }
                                disabled={isTransportApproved || formData.isHeadOfSeries || isSeriesTerm}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </FormControl>
            {isSeriesTerm && (
                <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Der Transporttyp kann für Serientermine nicht geändert werden.
                </Typography>
            )}
            {(formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT') && (!isEditMode || formData.isHeadOfSeries) && (
                <Box mt={2}>
                    <FormControl component="fieldset">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <FormControlLabel
                                control={<Switch
                                    checked={isRecurring}
                                    onChange={handleRecurringChange}
                                    disabled={isEditMode}
                                    inputProps={{ 'data-testid': 'recurring-transport-switch' }}
                                />}
                                label="Regelmäßiger Transport?"
                                labelPlacement="start" // Platzierung des Labels links vom Switch
                                sx={{ marginLeft: 0 }}
                            />
                            {/* Link/Button zum Öffnen des Dialogs */}
                            {isRecurring && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer', // Cursor bleibt klickbar
                                        marginLeft: '34px',
                                    }}
                                    onClick={handleOpenDialog} // Klick auf Icon oder Text löst das Event aus
                                >
                                    <CalendarMonthIcon sx={{ marginRight: '8px', color: theme.palette.primary.main }} />
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Einstellungen bearbeiten
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </FormControl>
                    {!recurringError && isRecurring && (
                        <Box mt={2}>
                            <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                Hinfahrten: {getRecurringSummary(formData.recurring_days, formData.recurring_times)}
                            </Typography>
                            <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                Rückfahrten: {getRecurringSummary(formData.recurring_return_days, formData.recurring_return_times)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            <Box mt={isExpertMode ? 1 : 4} display="flex" alignItems="center">
                {!isRecurring && (formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT') && (
                    <Box ml={0} mt={2}>
                        {!isEditMode && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-testid="initial-trip-checkbox" // Eindeutiges Attribut hinzufügen
                                            checked={formData.initial_trip || false}
                                            onChange={handleInitalTripChange}
                                            onKeyDown={(event) => {
                                                // Überprüfen, ob die Enter-Taste gedrückt wurde
                                                if (event.key === 'Enter') {
                                                    event.preventDefault(); // Verhindert das Standardverhalten
                                                    setFormData(prev => ({ ...prev, initial_trip: !prev.initial_trip }));
                                                }
                                            }}
                                            name="initialTrip"
                                            color="primary"
                                            tabIndex={0} // Fokussierbar machen
                                            sx={{ marginLeft: '10px' }}
                                        />
                                    }
                                    label="Hinfahrt (ja/nein)"
                                    labelPlacement="start" // Platzierung des Labels links vom Switch
                                    sx={{
                                        marginLeft: 0,
                                        color: noCheckboxChecked ? '#E61F02' : 'inherit', // Rot wenn keine Checkbox ausgewählt
                                    }}
                                />

                                {/* HINFAHRT bei TERMINFAHRT oder DIALYSEFAHRT */}
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <DateTimePicker
                                        desktopModeMediaQuery="@media (min-width: 0px)" // Desktop-Modus erzwingen
                                        label="Ankunft Zielort"
                                        name="departureDateTimeDia"
                                        value={formData.departureDateTime || null}
                                        onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                                        margin="normal"
                                        fullWidth
                                        ampm={false}
                                        format="DD.MM.YYYY HH:mm"
                                        disablePast
                                        maxDate={maxDate}
                                        minDateTime={minDate}
                                        disabled={!isEditMode && (isTransportApproved || !formData.initial_trip)}
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                disabled: !isEditMode && (isTransportApproved || !formData.initial_trip), // Gleiche Bedingung für das TextField
                                                error: !isEditMode && !isTransportApproved && formData.initial_trip && !!dateError,
                                                helperText: (!isEditMode && !isTransportApproved && formData.initial_trip && errorMessage) || "", // Helper Text nur anzeigen, wenn aktiv
                                            },
                                        }}
                                        sx={{ marginLeft: '16px' }}
                                    />
                                </LocalizationProvider>
                            </>
                        )}
                        <br />
                        {!isEditMode && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.return_trip || false}
                                            onChange={handleReturnTripChange}
                                            onKeyDown={(event) => {
                                                // Überprüfen, ob die Enter-Taste gedrückt wurde
                                                if (event.key === 'Enter') {
                                                    event.preventDefault(); // Verhindert das Standardverhalten
                                                    setFormData(prev => ({ ...prev, return_trip: !prev.return_trip }));
                                                }
                                            }}
                                            name="returnTrip"
                                            color="primary"
                                            tabIndex={0} // Fokussierbar machen
                                        />
                                    }
                                    label="Rückfahrt (ja/nein)"
                                    labelPlacement="start"
                                    sx={{
                                        marginLeft: 0,
                                        marginTop: 1,
                                        color: noCheckboxChecked ? '#E61F02' : 'inherit', // Rot wenn keine Checkbox ausgewählt
                                    }}
                                />
                                {/* RÜCKFAHRT bei TERMINFAHRT oder DIALYSEFAHRT */}
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <DateTimePicker
                                        desktopModeMediaQuery="@media (min-width: 0px)" // Desktop-Modus erzwingen
                                        label="Wunschzeit Abfahrt"
                                        name="returnDepartureTime"
                                        value={formData.returnDepartureTime || getDefaultReturnTime()}
                                        onChange={(date) => handleChange({ target: { name: 'returnDepartureTime', value: date } })}
                                        margin="normal"
                                        fullWidth
                                        ampm={false}
                                        format="DD.MM.YYYY HH:mm"
                                        disablePast
                                        maxDate={maxDate}
                                        minDateTime={getMinReturnTime()}
                                        disabled={isTransportApproved || !formData.return_trip}
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                disabled: isTransportApproved || !formData.return_trip, // TextField deaktivieren
                                                error: !isTransportApproved && formData.return_trip && !!dateError, // Fehler nur anzeigen, wenn aktiv (als Boolean)
                                                helperText: (!isTransportApproved && formData.return_trip && errorMessage) || "", // Helfertext nur anzeigen, wenn aktiv
                                            },
                                        }}
                                        sx={{ marginLeft: '15px', marginTop: 1 }}
                                    />
                                </LocalizationProvider>
                            </>
                        )}
                    </Box>
                )}
                {
                    (formData.transport_type === 'ENTLASSUNG' || formData.transport_type === 'EINWEISUNG' || formData.transport_type === 'VERLEGUNG'
                        || ((formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT') && isEditMode && !formData.isHeadOfSeries)) && (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                desktopModeMediaQuery="@media (min-width: 0px)" // Desktop-Modus erzwingen
                                label={formData.transport_type === 'TERMINFAHRT' ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'}
                                name="departureDateTime"
                                value={formData.departureDateTime || null}
                                onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                                margin="normal"
                                fullWidth
                                ampm={false}
                                format="DD.MM.YYYY HH:mm"
                                disablePast
                                maxDate={maxDate}
                                minDateTime={minDate}
                                disabled={isTransportApproved}
                                onError={(newError) => setDateError(newError)}
                                slotProps={{
                                    textField: {
                                        disabled: isTransportApproved, // TextField deaktivieren
                                        error: !isTransportApproved && !!(recurringTimeErrors.length > 0), // Fehler nur anzeigen, wenn aktiv
                                        helperText: (!isTransportApproved && errorMessage) || "", // Helfertext nur anzeigen, wenn aktiv
                                        inputProps: {
                                            'data-testid': 'departure-date-time-picker', // Test-ID hinzufügen
                                        },
                                    },
                                }}
                                sx={{
                                    marginLeft: '0px',
                                    marginTop: isSeriesTerm ? '20px' : undefined, // marginTop nur bei isSeriesTerm
                                }}
                            />
                        </LocalizationProvider>
                    )}
            </Box>
            <>
                {/* Fullscreen Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="lg"
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '100%',
                            maxWidth: '1200px',
                        },
                    }}
                >
                    <DialogTitle>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Einstellungen</Typography>
                        </Box>
                    </DialogTitle>

                    <DialogContent>
                        <Box mt={2}>
                            {/* Checkbox für regelmäßige Hinfahrten */}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.is_recurring || false}
                                        onChange={(event) => setFormData((prevData) => ({
                                            ...prevData,
                                            is_recurring: event.target.checked,
                                        }))}
                                    />
                                }
                                label="Regelmäßige Hinfahrten?"
                                labelPlacement="end"
                            />
                            <Grid container spacing={2}>
                                {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(day => (
                                    <Grid item xs={12} sm={4} key={day}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!!formData.recurring_days?.[day]}
                                                    disabled={!formData.is_recurring}
                                                    onChange={(event) => handleDayChange(event, day)}
                                                />
                                            }
                                            label={day}
                                        />
                                        <RestrictedTimePicker
                                            day={day}
                                            time={formData.recurring_times?.[day]}
                                            onChange={handleTimeChange}
                                            transportType={formData.transport_type}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {/* Checkbox für regelmäßige Rückfahrten */}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.is_recurring_return || false}
                                        onChange={(event) => setFormData((prevData) => ({
                                            ...prevData,
                                            is_recurring_return: event.target.checked,
                                        }))}
                                    />
                                }
                                label="Regelmäßige Rückfahrten?"
                                labelPlacement="end"
                                sx={{ marginTop: 4 }}
                            />
                            <Grid container spacing={2}>
                                {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((day) => (
                                    <Grid item xs={12} sm={4} key={`return-${day}`}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!!formData.recurring_return_days?.[day]}
                                                    disabled={!formData.is_recurring_return}
                                                    onChange={(event) => handleReturnDayChange(event, day)}
                                                />
                                            }
                                            label={day}
                                        />
                                        <RestrictedTimePicker
                                            day={day}
                                            time={formData.recurring_return_times?.[day]}
                                            onChange={handleReturnTimeChange}
                                            transportType={formData.transport_type}
                                            isReturn={true}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>

                        <Box
                            display="flex"
                            // alignItems="center"
                            justifyContent="space-between" // Verteilt den Platz zwischen den Elementen
                            width="100%"
                        >
                            <Button onClick={handleCloseDialog} variant="text" sx={{ ml: 1 }} tabIndex={-1}>
                                Abbrechen
                            </Button>
                            <Button onClick={handleSaveDialog} variant="outlined" sx={{ mr: 1 }} tabIndex={-1}>
                                Einstellungen Speichern
                            </Button>


                        </Box>
                    </DialogActions>
                </Dialog>
                {!isExpertMode && formData.transport_type && (formData.departureDateTime === null || formData.departureDateTime === undefined) && (
                    <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
                        {getTimeRangeInfoText(
                            formData.departureDateTime,
                            formData.transport_type,
                            timeSettings
                        )}
                    </Typography>
                )}
                {/* Fehlermeldung anzeigen */}
                {
                    recurringTimeErrors.length > 0 && formData.transport_type && (
                        <Box mt={1}>
                            {recurringTimeErrors.map((error, index) => (
                                <Typography key={index} variant="body2" color="#E61F02">
                                    {error}
                                </Typography>
                            ))}
                        </Box>
                    )
                }
                {!isExpertMode && !formData.isHeadOfSeries && getTimeRangeText(formData.departureDateTime, formData.transport_type, timeSettings) && recurringTimeErrors.length === 0 && (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {getTimeRangeText(formData.departureDateTime, formData.transport_type, timeSettings)}
                    </Typography>
                )}

            </>
        </TransportDesignBox>
    );
};

export default WhenExpertStep;