import React, { useState } from 'react';
import { AppBar, Avatar, Divider, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useUser } from './userContext'
import { useNavigate } from 'react-router-dom';
import { NavItem } from './nav-item';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsIcon from '@mui/icons-material/Settings';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function TopMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, logout } = useUser();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    /*  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
         defaultMatches: true,
         noSsr: false
     }); */
    const [navMenuAnchor, setNavMenuAnchor] = useState(null);

    const [subMenuAnchor, setSubMenuAnchor] = useState(null);

    const handleSubMenuOpen = (event) => {
        setSubMenuAnchor(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchor(null);
    };

    const handleNavMenuOpen = (event) => {
        setNavMenuAnchor(event.currentTarget);
    };

    const handleNavMenuClose = () => {
        setNavMenuAnchor(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePwChange = () => {
        setAnchorEl(null);
        navigate('/changepassword');
    };

    const handleLogout = () => {
        console.log('Logout: ' + user.username);
        logout();
        navigate('/login');
    };

    return (
        <AppBar position="static" color="default" style={{ backgroundColor: '#fff' }}>
            <Toolbar style={{ alignItems: isSmallScreen ? 'center' : 'flex-end' }}>
                {/* Logo */}
                <img src="drk-logo.jpg" alt="Logo" style={{ height: isSmallScreen ? '60px' : '80px' }} />
                {!isSmallScreen && (
                    <div style={{ marginLeft: '2px', marginBottom: '4px' }}>
                        <img src="DRK_RD_BOS.png" alt="Logo" />
                    </div>
                )}

                <div style={{ flexGrow: 1 }}></div> {/* Dies sorgt dafür, dass der Avatar nach rechts verschoben wird */}

                {user && (
                    <>
                        {/* Navigation Links Container */}
                        {isSmallScreen ? (
                            <>
                                <IconButton color="inherit" onClick={handleNavMenuOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={navMenuAnchor}
                                    open={Boolean(navMenuAnchor)}
                                    onClose={handleNavMenuClose}
                                >
                                    <MenuItem onClick={handleNavMenuClose}>
                                        <NavItem key="Dashboard" icon={<HomeIcon />} href="/dashboard" title="Startseite" />
                                    </MenuItem>
                                    <MenuItem onClick={handleNavMenuClose}>
                                        <NavItem key="Bestellungen" icon={<AirportShuttleIcon />} href="/transportlist" title="Bestellungen" />
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <>
                                <Stack direction="row" spacing={1}>
                                    <NavItem key="Dashboard" icon={<HomeIcon />} href="/dashboard" title="Startseite" />
                                    <NavItem key="Bestellungen" icon={<AirportShuttleIcon />} href="/transportlist" title="Bestellungen" />
                                    {user?.role === 'admin' && (
                                        <>
                                            <MenuItem onClick={handleSubMenuOpen}>
                                                <SettingsIcon />
                                                <Typography variant="body2" sx={{
                                                    backgroundColor: 'rgba(255,255,255, 0.04)', // Reduzierte Opazität für aktiven Zustand
                                                    borderRadius: 1,
                                                    marginLeft: 1,
                                                    justifyContent: 'flex-start',
                                                    textAlign: 'left',
                                                    textTransform: 'none',
                                                    width: '100%',
                                                    fontWeight: 'bold'  // Fettgedruckter Text
                                                }}>
                                                    Administration
                                                </Typography>
                                                <ExpandMoreIcon />
                                            </MenuItem>
                                            <Menu
                                                anchorEl={subMenuAnchor}
                                                open={Boolean(subMenuAnchor)}
                                                onClose={handleSubMenuClose}
                                            >
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="Institutionen" icon={<ApartmentIcon />} href="/institutions" title="Institutionen" />
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="Benutzer" icon={<PeopleIcon />} href="/userspage" title="Benutzer" />
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="Infektionen" icon={<HealthAndSafetyIcon />} href="/infections" title="Infektionen" />
                                                </MenuItem>
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="Zeitkorridore" icon={<AccessTimeIcon />} href="/timeSlotManagement" title="Zeitkorridore" />
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="LogFiles" icon={<FindInPageIcon />} href="/logs" title="Log-Files" />
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="Paramter" icon={<HealthAndSafetyIcon />} href="/params" title="Parameter" />
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={handleSubMenuClose}>
                                                    <NavItem key="ReleaseNotes" icon={<PermDeviceInformationIcon />} href="/releasenotes" title="Release-Notes" />
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </Stack>
                                <div style={{ flexGrow: 1 }}></div> {/* Dies sorgt dafür, dass der Avatar nach rechts verschoben wird */}
                            </>

                        )}
                    </>
                )}


                {/* Profil-Avatar mit Menü */}
                {user && (
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar src="/profile-circle.svg" />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {/* Benutzerinformationen (Benutzername und Rolle) */}
                            <div style={{ marginLeft: '12px', marginBottom: '12px' }}>
                                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                    {user && user.username}
                                </Typography>
                                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                    Rolle: {user && (user.role === 'poweruser' ? 'Disponent' : user.role)}
                                </Typography>
                            </div>
                            <Divider />
                            <MenuItem onClick={handlePwChange}>Passwort ändern</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default TopMenu;
