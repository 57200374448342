import React from 'react';
import { Typography } from '@mui/material';
import {
    Pause as PauseIcon,
    ThumbUpOffAlt as ThumbUpOffAltIcon,
    DateRange as DateRangeIcon,
    LocalShipping as LocalShippingIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    DoDisturb as DoDisturbIcon,
} from '@mui/icons-material';

const StatusCell = ({ status }) => {
    // Icon und Farbe basierend auf dem Status auswählen
    let IconComponent;
    let iconColor;
    let statusText;

    switch (status) {
        case 'pending':
            IconComponent = PauseIcon;
            iconColor = 'blue';
            statusText = 'in Prüfung';
            break;
        case 'approved':
            IconComponent = ThumbUpOffAltIcon;
            iconColor = 'blue';
            statusText = 'Akzeptiert';
            break;
        case 'scheduled':
            IconComponent = DateRangeIcon;
            iconColor = 'green';
            statusText = 'vorgeplant';
            break;
        case 'in_transit':
            IconComponent = LocalShippingIcon;
            iconColor = 'green';
            statusText = 'unterwegs';
            break;
        case 'finished':
            IconComponent = CheckCircleOutlineIcon;
            iconColor = 'green';
            statusText = 'beendet';
            break;
        case 'canceled':
            IconComponent = DoDisturbIcon;
            iconColor = 'gray';
            statusText = 'Storniert';
            break;
        default:
            IconComponent = DoDisturbIcon;
            iconColor = 'red';
            statusText = 'Abgelehnt';
    }

    return (
        <div style={{
            display: 'flex',
            //alignItems: 'center',
            // justifyContent: 'center',
            height: '100%',
            marginTop: 4,
            opacity: status === 'canceled' ? 1 : 1, // Geringere Deckkraft für stornierte Fahrten
        }}>
            <IconComponent style={{ color: iconColor, marginRight: 5 }} />
            <Typography
                variant="body2"
                style={{ color: iconColor }}
            >
                {statusText}
            </Typography>
        </div>
    );
};

export default StatusCell;