// transportWhenStep.js
import React, { useState, useEffect } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Checkbox
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useUser } from '../components/userContext';
import BooleanButtonGroup from '../components/buttonGroup';
import TransportDesignBox from '../components/TransportDesignBox';
import { validateTransportTime } from '../utils/validationLogic';
import { getTimeRangeText, getTimeRangeInfoText } from '../utils/whenStepHelpers';

const transportTypes = [
    { label: 'Terminfahrt', value: 'TERMINFAHRT' },
    { label: 'Entlassung', value: 'ENTLASSUNG' },
    { label: 'Einweisung', value: 'EINWEISUNG' },
    { label: 'Verlegung', value: 'VERLEGUNG' },
];

const WhenStep = ({ formData, setFormData, isExpertMode, timeSettings }) => {
    const [dateError, setDateError] = useState(null);
    const { user } = useUser();
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);

    // Validierung beim Rendern oder bei Änderungen
    useEffect(() => {
        const validationError = validateTransportTime(
            formData.departureDateTime,
            formData.transport_type,
            timeSettings,
            user
        );
        setDateError(validationError);
    }, [formData.departureDateTime, formData.transport_type, timeSettings, user]);

    const handleDateTimeChange = (date) => {

        setFormData(prevData => ({
            ...prevData,
            departureDateTime: date,
            initial_trip: true
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'departureDateTime' || name === 'transport_type') {
            const validationError = validateTransportTime(
                name === 'departureDateTime' ? value : formData.departureDateTime,
                name === 'transport_type' ? value : formData.transport_type,
                timeSettings,
                user
            );
            setDateError(validationError);
        }
    };

    const minDate = dayjs().add(60, 'minutes');
    const maxDate = dayjs().add(10, 'years');

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6">Wann soll der Transport durchgeführt werden?</Typography>

            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Art des Transports</FormLabel>
                <RadioGroup
                    row
                    aria-label="transport_type"
                    name="transport_type"
                    value={formData.transport_type || ''}
                    onChange={handleChange}
                    disabled={isTransportApproved}
                >
                    {transportTypes.map((type) => (
                        <FormControlLabel
                            key={type.value}
                            value={type.value}
                            control={<Radio />}
                            label={type.label}
                            disabled={isTransportApproved}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <Box mt={isExpertMode ? 2 : 4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DateTimePicker
                        desktopModeMediaQuery="@media (min-width: 0px)" // Desktop-Modus erzwingen
                        label={formData.transport_type === 'TERMINFAHRT' ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'}
                        name="departureDateTime"
                        value={formData.departureDateTime || null}
                        onChange={handleDateTimeChange}
                        ampm={false}
                        format="DD.MM.YYYY HH:mm"
                        disabled={isTransportApproved}
                        maxDate={maxDate}
                        minDateTime={minDate}
                        slotProps={{
                            textField: {
                                error: !!dateError,
                                helperText: dateError,
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>

            {formData.transport_type && (formData.departureDateTime === null || formData.departureDateTime === undefined) && (
                <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
                    {getTimeRangeInfoText(
                        formData.departureDateTime,
                        formData.transport_type,
                        timeSettings
                    )}
                </Typography>
            )}
            {getTimeRangeText(formData.departureDateTime, formData.transport_type, timeSettings) && dateError === null && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    {getTimeRangeText(formData.departureDateTime, formData.transport_type, timeSettings)}
                </Typography>
            )}

            {formData.transport_type === 'TERMINFAHRT' && (
                <Box mt={2}>
                    {isExpertMode ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.ambulatory || false}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        ambulatory: e.target.checked
                                    }))}
                                    name="ambulatory"
                                />
                            }
                            label="Rückfahrt hinzufügen"
                        />
                    ) : (
                        <BooleanButtonGroup
                            fieldName="ambulatory"
                            label="Rückfahrt hinzufügen?"
                            options={[
                                { label: 'Ja', value: true },
                                { label: 'Nein', value: false },
                            ]}
                            formData={formData}
                            setFormData={setFormData}
                            customLabelStyle={{ width: '200px', marginRight: '2px' }}
                        />
                    )}
                </Box>
            )}
        </TransportDesignBox>
    );
};

export default WhenStep;