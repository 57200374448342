import React, { useEffect, useState } from 'react';
import {
    Typography,
    TextField,
    Box,
    Autocomplete,
    Link
} from '@mui/material';
import BooleanButtonGroup from '../components/buttonGroup';
import { useUser } from '../components/userContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TransportDesignBox from '../components/TransportDesignBox';
import SearchIcon from '@mui/icons-material/Search';


const PatientStep = ({ formData, setFormData, isExpertMode }) => {
    const { user } = useUser(); // Anbemeldeter User
    const [infektionen, setInfektionen] = useState([]);
    // eslint-disable-next-line
    const [newInfektionText, setNewInfektionText] = useState("");

    const [patients, setPatients] = useState([]); // State für Patienten-Stammdaten
    const [error, setError] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };

    useEffect(() => {
        // Debugging: Ausgeben, ob is_privat den richtigen Wert hat
        console.log("Aktueller Wert von is_privat:", formData.is_privat);
    }, [formData.is_privat]); // Überwachen von Änderungen an formData.is_privat


    useEffect(() => {
        fetchInfektionen();
        fetchPatients(); // Patienten-Stammdaten abrufen

        // Variablen vorbelegen, wenn der Expertenmodus aktiv ist
        if (isExpertMode) {
            setFormData((prevData) => ({
                ...prevData,
                weightChecked: true,
                monitorRequired: false,
                suctionRequired: false,
            }));
        }
        // eslint-disable-next-line
    }, [user, isExpertMode]);

    const fetchInfektionen = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setInfektionen(data); // Set the infektionen data
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Infektionen:', error.message);
        }
    };

    const fetchPatients = async () => {
        try {
            if (!user || !user?.institutionId) {
                console.error('Institution ID ist erforderlich');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/patients?institution_id=${user?.institutionId}`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setPatients(data); // Patienten-Stammdaten setzen
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Patienten-Stammdaten:', error.message);
        }
    };

    const handlePatientSelection = (patient) => {
        if (patient) {
            setFormData({
                ...formData,
                firstName: patient.first_name,
                lastName: patient.last_name,
                dateOfBirth: dayjs(patient.birthdate),  // Konvertiere das Geburtsdatum in ein dayjs-Objekt
                insuranceNumber: patient.insurance_number || '',
            });
        } else {
            // Falls der Patient gelöscht wird (z.B. durch den X-Button im Autocomplete)
            setFormData({
                ...formData,
                firstName: '',
                lastName: '',
                dateOfBirth: null,
                insuranceNumber: '',
            });
        }
    };

    const handleCopyName = () => {
        setFormData(prevData => ({
            ...prevData,
            firstName: formData.private_first_name || '',
            lastName: formData.private_last_name || ''
        }));
    };

    const selectedInfection = infektionen.find(inf => inf.infektion_id === formData.infektion_id);

    const today = dayjs();
    const veryOldDate = dayjs().subtract(120, 'years');

    const errorMessage = React.useMemo(() => {
        switch (error) {

            case 'maxDate':
            case 'minDate': {
                return 'Ungültiges Geburtsdatum. Bitte prüfen...';
            }

            case 'disableFuture': {
                return 'Geburtsdatum liegt in der Zukunft. Bitte prüfen...';
            }

            case 'invalidDate': {
                return 'Datum nicht korrekt';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={540}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Angaben zum Patienten</Typography>
                {formData.is_private && (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={handleCopyName}
                        underline="hover"
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                    >
                        Namen des Bestellers übernehmen
                    </Link>
                )}
            </Box>

            {!isExpertMode && (
                <BooleanButtonGroup
                    name="is_weight_checked"
                    fieldName="weightChecked"
                    label="Gewicht kleiner als 150 Kg?"
                    options={[
                        { label: 'Ja', value: true },
                        { label: 'Nein', value: false },
                    ]}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}
            <div>
                {formData.weightChecked === false && (
                    <span>
                        <Typography variant="body1" color="error">
                            Der Transport kann nicht für Patienten mit einem Gewicht von 150 kg oder mehr angefordert werden.<br />Bitte rufen Sie uns an! Die Telefonnummer lautet: 0751/19222. <br />Vielen Dank.
                        </Typography>
                    </span>
                )}
            </div>
            <div>
                {!isExpertMode && formData.weightChecked === true && (
                    <span>
                        <BooleanButtonGroup
                            fieldName="monitorRequired"
                            label="Patient Monitorpflichtig?"
                            options={[
                                { label: 'Ja', value: true },
                                { label: 'Nein', value: false },
                            ]}
                            formData={formData}
                            setFormData={setFormData}
                        />
                        <div>
                            {formData.monitorRequired === true && (
                                <span>
                                    <Typography variant="body1" color="error">
                                        Der Transport kann nicht für monitorpflichtige Patienten angefordert werden.<br />Bitte rufen Sie uns an! Die Telefonnummer lautet: 0751/19222. <br />Vielen Dank.
                                    </Typography>
                                </span>
                            )}
                        </div>
                    </span>
                )}
            </div>
            <div>
                {!isExpertMode && formData.weightChecked === true && formData.monitorRequired === false && (
                    <span>
                        <BooleanButtonGroup
                            fieldName="suctionRequired"
                            label="Patient Absaugpflichtig?"
                            options={[
                                { label: 'Ja', value: true },
                                { label: 'Nein', value: false },
                            ]}
                            formData={formData}
                            setFormData={setFormData}
                        />
                        <div>
                            {formData.suctionRequired === true && (
                                <span>
                                    <Typography variant="body1" color="error">
                                        Der Transport kann nicht für absaugpflichtige Patienten angefordert werden.<br />Bitte rufen Sie uns an! Die Telefonnummer lautet: 0751/19222. <br />Vielen Dank.
                                    </Typography>
                                </span>
                            )}
                        </div>
                    </span>
                )}
            </div>
            <div>
                {(formData.weightChecked === true && formData.monitorRequired === false && formData.suctionRequired === false) && (
                    <span>

                        <Box display="flex" alignItems="center" style={{ marginTop: '26px', marginBottom: '4px' }}>
                            <SearchIcon style={{ marginRight: '4px' }} />
                            <Typography variant="caption">
                                Schnellsuche in den Stammdaten für Patienten
                            </Typography>
                        </Box>
                        {/* Autocomplete für Patientensuche */}
                        <Autocomplete
                            style={{ marginBottom: '16px' }}
                            options={patients}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${dayjs(option.birthdate).format('DD.MM.YYYY')})`}
                            onChange={(event, newValue) => handlePatientSelection(newValue)}
                            renderInput={(params) => <TextField {...params} label="Patient aus Stammdaten auswählen" fullWidth />}
                        />
                        <TextField
                            label="Nachname *"
                            name="lastName"
                            value={formData.lastName || ''}
                            onChange={handleChange}
                            margin={isExpertMode ? "dense" : "normal"}
                            size={isExpertMode ? "small" : "normal"}
                            fullWidth
                            error={!formData.lastName}
                            helperText={!formData.lastName && (isExpertMode ? '' : 'Nachname ist erforderlich')}
                        />
                        <TextField
                            label="Vorname *"
                            name="firstName"
                            value={formData.firstName || ''}
                            onChange={handleChange}
                            margin={isExpertMode ? "dense" : "normal"}
                            size={isExpertMode ? "small" : "normal"}
                            fullWidth
                            error={!formData.firstName}
                            helperText={!formData.firstName && (isExpertMode ? '' : 'Vorname ist erforderlich')}
                        />
                        <Box mt={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                                <DatePicker
                                    desktopModeMediaQuery="@media (min-width: 0px)"
                                    label="Geburtsdatum *"
                                    name="dateOfBirth"
                                    value={formData.dateOfBirth || null}
                                    onChange={(date) => handleChange({ target: { name: 'dateOfBirth', value: date } })}
                                    margin={isExpertMode ? "dense" : "normal"}
                                    fullWidth
                                    format="DD.MM.YYYY"
                                    disableFuture
                                    defaultValue={dayjs('1970-01-01')}
                                    maxDate={today}
                                    minDate={veryOldDate}
                                    onError={(newError) => setError(newError)}
                                    slotProps={{
                                        textField: {
                                            error: error || !formData.dateOfBirth, // Set error prop
                                            helperText: errorMessage, // Set helper text
                                            sx: {
                                                // Anpassungen für den "dense"-Modus
                                                '& .MuiInputLabel-root': {
                                                    transform: isExpertMode ? 'translate(14px, 9px) scale(1)' : 'translate(14px, 16px) scale(1)', // Label-Positionierung
                                                    '&.MuiInputLabel-shrink': {
                                                        transform: isExpertMode ? 'translate(14px, -9px) scale(0.75)' : 'translate(14px, -6px) scale(0.75)', // Label-Positionierung bei Fokus
                                                    },
                                                },
                                                '& .MuiInputBase-root': {
                                                    height: isExpertMode ? '40px' : '56px', // Höhe anpassen
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: isExpertMode ? '0.875rem' : '1rem', // Schriftgröße anpassen
                                                    padding: isExpertMode ? '8px 14px' : '16px', // Padding anpassen
                                                },
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <TextField
                            label="Vers.-Nummer"
                            name="insuranceNumber"
                            value={formData.insuranceNumber || ''}
                            onChange={handleChange}
                            margin={isExpertMode ? "dense" : "normal"}
                            size={isExpertMode ? "small" : "normal"}
                            fullWidth
                            style={{ marginTop: isExpertMode ? '20px' : undefined }}
                        />
                        {/* Auskommentiert auf Anforderung der ILS V 1.2.x */}
                        {/*  <TextField
                            label="Anlass Transport"
                            name="reasonForTransport"
                            value={formData.reasonForTransport || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                        /> */}
                        <BooleanButtonGroup
                            fieldName="infectious"
                            label="Infektiös?"
                            options={[
                                { label: 'Ja', value: true },
                                { label: 'Nein', value: false },
                            ]}
                            formData={formData}
                            setFormData={setFormData}
                        />
                        {formData.infectious === true && (
                            <div>
                                <Autocomplete
                                    data-testid="infektionen"
                                    options={infektionen}
                                    value={selectedInfection || null}
                                    onChange={(event, newValue) => {
                                        if (newValue === null) {
                                            // Handle the case when the selection is cleared (x button)
                                            setFormData({
                                                ...formData,
                                                infektion_id: ''
                                            });
                                            // setNewSelectedInfektion(null);
                                        } else {
                                            // Check if the selected infection is already in the list
                                            const isInList = infektionen.some(inf => inf.infektion_id === newValue.infektion_id);


                                            setFormData({
                                                ...formData,
                                                infektion_id: newValue ? newValue.infektion_id : ''
                                            });

                                            // Set new selected infection only if it's not already in the list
                                            if (!isInList) {
                                                //  setNewSelectedInfektion(newValue);
                                            }
                                        }
                                    }}
                                    getOptionLabel={(option) => option.infektion}
                                    renderInput={(params) => <TextField {...params} label="Infektion" error={!formData.infektion_id}
                                        helperText={!formData.infektion_id && 'Infektion ist erforderlich'} />}
                                    onInputChange={(event, newInputValue) => {
                                        setNewInfektionText(newInputValue);
                                    }}


                                />
                            </div>
                        )}
                    </span>
                )}
            </div>
        </TransportDesignBox>
    );
};

export default PatientStep;
