import React, { useState, useEffect } from 'react';
import {
    Alert,
    Checkbox,
    FormControlLabel,
    Stack,
    Typography,
    Button,
    Dialog,
    IconButton,
    RadioGroup,
    Radio,
    FormControl,
} from '@mui/material';
import BooleanButtonGroup from '../components/buttonGroup';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import TransportDesignBox from '../components/TransportDesignBox';

const TransportDetailsStep = ({ formData, setFormData, isExpertMode }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isExpertMode && formData && !formData.initialized) {
            const updatedData = {
                ...formData,
                own_wheelchair: formData.own_wheelchair ?? false,
                folding: formData.folding ?? true,
                confirm_folding: formData.confirm_folding ?? true,
                luggage: formData.luggage ?? false,
                confirm_luggage: formData.confirm_luggage ?? true,
                companion: formData.companion ?? false,
                confirm_companion: formData.confirm_companion ?? true,
                oxygen_required: formData.oxygen_required ?? false,
                prescription_present: formData.prescription_present ?? false,
                insurance_approved: formData.insurance_approved ?? true,
                confirm_insurance_approved: formData.confirm_insurance_approved ?? true,
                confirm_prescription_present: formData.confirm_prescription_present ?? true,
                oxygen_source: formData.oxygen_source ?? 'ab_fahrzeug',
                self_payer: formData.self_payer ?? false,
            };

            // Nur aktualisieren, wenn die Daten tatsächlich geändert wurden
            if (JSON.stringify(updatedData) !== JSON.stringify(formData)) {
                setFormData(updatedData);
            }
        }
    }, [isExpertMode, formData, setFormData]);

    const handleCheckboxChange = (field) => (event) => {
        const newValue = event.target.checked;
        setFormData(prevData => ({
            ...prevData,
            [field]: newValue,
        }));

        // Wenn eines der beiden Felder (prescription_present oder self_payer) geändert wird
        if (field === 'prescription_present' || field === 'self_payer') {
            // Setze den anderen Boolean auf false, wenn der aktuelle Boolean auf true gesetzt wird
            setFormData(prevData => ({
                ...prevData,
                [field]: newValue,
                prescription_present: field === 'self_payer' && newValue ? false : prevData.prescription_present,
                self_payer: field === 'prescription_present' && newValue ? false : prevData.self_payer,
            }));
        } else {
            // Normales Verhalten für andere Checkboxen
            setFormData(prevData => ({
                ...prevData,
                [field]: newValue,
            }));
        }

    };

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6" mb={2}>Details zum Transport</Typography>

            <BooleanButtonGroup
                fieldName="transport_mode"
                label="Transportart"
                options={[
                    { label: 'Sitzend', value: 'sitzend' },
                    { label: 'Liegend', value: 'liegend' },
                ]}
                formData={formData}
                setFormData={setFormData}
            />

            {/* Conditional fields based on transport_mode */}
            {!isExpertMode && (
                <BooleanButtonGroup
                    fieldName="insurance_approved"
                    label="Wurde der Transport durch die Krankenkasse genehmigt?"
                    options={[
                        { label: 'Ja', value: true },
                        { label: 'Nein', value: false },
                    ]}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}

            {/* Other Options */}
            {isExpertMode ? (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!formData.own_wheelchair}
                                onChange={handleCheckboxChange('own_wheelchair')}
                                onKeyDown={(event) => {
                                    // Aktivierung auf Leertaste sicherstellen
                                    if (event.key === 'Enter') {
                                        event.preventDefault(); // Verhindert Scrollen
                                        handleCheckboxChange('own_wheelchair')({ target: { checked: !formData.own_wheelchair } });
                                    }
                                }}
                                tabIndex={0} // Checkbox fokussierbar machen
                            />
                        }
                        label="Rollstuhl"
                    />
                </>
            ) : (
                <>
                    <BooleanButtonGroup
                        fieldName="own_wheelchair"
                        label="Eigener Rollstuhl vorhanden?"
                        options={[
                            { label: 'Ja', value: true },
                            { label: 'Nein', value: false },
                        ]}
                        formData={formData}
                        setFormData={setFormData}
                    />
                </>
            )}

            {/* Folding wheelchair only if own_wheelchair is true */}
            {!isExpertMode && formData.own_wheelchair === true && (
                <BooleanButtonGroup
                    fieldName="folding"
                    label="Ist der Rollstuhl klappbar?"
                    options={[
                        { label: 'Ja', value: true },
                        { label: 'Nein', value: false },
                    ]}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}

            {/* More Checkboxes */}
            {isExpertMode ? (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!formData.luggage}
                                onChange={handleCheckboxChange('luggage')}
                                onKeyDown={(event) => {
                                    // Aktivierung auf Leertaste sicherstellen
                                    if (event.key === 'Enter') {
                                        event.preventDefault(); // Verhindert Scrollen
                                        handleCheckboxChange('luggage')({ target: { checked: !formData.luggage } });
                                    }
                                }}
                                tabIndex={0} // Checkbox fokussierbar machen
                            />
                        }
                        label="Gepäck"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!formData.companion}
                                onChange={handleCheckboxChange('companion')}
                                onKeyDown={(event) => {
                                    // Aktivierung auf Leertaste sicherstellen
                                    if (event.key === 'Enter') {
                                        event.preventDefault(); // Verhindert Scrollen
                                        handleCheckboxChange('companion')({ target: { checked: !formData.companion } });
                                    }
                                }}
                                tabIndex={0} // Checkbox fokussierbar machen
                            />
                        }
                        label="Begleitperson"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!formData.oxygen_required}
                                onChange={handleCheckboxChange('oxygen_required')}
                                onKeyDown={(event) => {
                                    // Aktivierung auf Leertaste sicherstellen
                                    if (event.key === 'Enter') {
                                        event.preventDefault(); // Verhindert Scrollen
                                        handleCheckboxChange('oxygen_required')({ target: { checked: !formData.oxygen_required } });
                                    }
                                }}
                                tabIndex={0} // Checkbox fokussierbar machen
                            />
                        }
                        label="Sauerstoffpflichtig"
                    />

                    {/* Display oxygen_source only if oxygen_required is true */}
                    {formData.oxygen_required && (
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={formData.oxygen_source}
                                onChange={(event) => setFormData(prev => ({
                                    ...prev,
                                    oxygen_source: event.target.value
                                }))}
                            >
                                <FormControlLabel value="ab_patient" control={<Radio tabIndex={0} />} label="ab Patient" />
                                <FormControlLabel value="ab_fahrzeug" control={<Radio tabIndex={0} />} label="ab Fahrzeug" />
                            </RadioGroup>
                        </FormControl>
                    )}

                    {formData.oxygen_required && (<br></br>)}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!formData.prescription_present}
                                onChange={handleCheckboxChange('prescription_present')}
                                onKeyDown={(event) => {
                                    // Aktivierung auf Leertaste sicherstellen
                                    if (event.key === 'Enter') {
                                        event.preventDefault(); // Verhindert Scrollen
                                        handleCheckboxChange('prescription_present')({ target: { checked: !formData.prescription_present } });
                                    }
                                }}
                                tabIndex={0} // Checkbox fokussierbar machen
                            />
                        }
                        label="Verordnung"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-testid="self_payer"
                                checked={!!formData.self_payer}
                                onChange={handleCheckboxChange('self_payer')}
                                onKeyDown={(event) => {
                                    // Aktivierung auf Leertaste sicherstellen
                                    if (event.key === 'Enter') {
                                        event.preventDefault(); // Verhindert Scrollen
                                        handleCheckboxChange('self_payer')({ target: { checked: !formData.self_payer } });
                                    }
                                }}
                                tabIndex={0} // Checkbox fokussierbar machen
                            />
                        }
                        label="Selbstzahler"
                    />
                </>
            ) : (
                <>
                    {/* Non-Expert Mode with BooleanButtonGroups */}
                    <BooleanButtonGroup fieldName="luggage" label="Gepäck vorhanden?" options={[{ label: 'Ja', value: true }, { label: 'Nein', value: false }]} formData={formData} setFormData={setFormData} />
                    <BooleanButtonGroup fieldName="companion" label="Begleitperson?" options={[{ label: 'Ja', value: true }, { label: 'Nein', value: false }]} formData={formData} setFormData={setFormData} />
                    <BooleanButtonGroup fieldName="oxygen_required" label="Patient Sauerstoffpflichtig?" options={[{ label: 'Ja', value: true }, { label: 'Nein', value: false }]} formData={formData} setFormData={setFormData} />
                    {formData.oxygen_required === true && (
                        <BooleanButtonGroup
                            fieldName="oxygen_source"
                            label="Sauerstoff ab:"
                            options={[
                                { label: 'Patient', value: 'ab_patient' },
                                { label: 'Fahrzeug', value: 'ab_fahrzeug' },
                            ]}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    )}
                    <BooleanButtonGroup fieldName="prescription_present" label="Liegt eine gültige Verordnung vor?" options={[{ label: 'Ja', value: true }, { label: 'Nein', value: false }]} formData={formData} setFormData={setFormData} />
                    {/* 
                    <BooleanButtonGroup
                        fieldName="self_payer"
                        label="Selbstzahler?"
                        options={[
                            { label: 'Ja', value: true },
                            { label: 'Nein', value: false },
                        ]}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    */}
                </>
            )}

            {/* Fehlermeldung, wenn weder Verordnung noch Selbstzahler ausgewählt ist */}
            {/*
            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}
                 */}

            <>
                {/* Info-Abschnitt nur im Nicht-Expert Mode */}
                {!isExpertMode && (
                    <Stack direction="row" sx={{ mt: 0 }}>
                        <Button
                            variant="text"
                            onClick={handleClickOpen}
                            startIcon={<InfoIcon />}
                            sx={{
                                fontStyle: 'italic',
                                fontSize: '0.8rem'
                            }}
                        >
                            Was ist eine gültige Verordnung?
                        </Button>
                    </Stack>
                )}

                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="lg"
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Alert severity="info" sx={{ margin: 2, fontSize: '1.2em' }}>
                        In dem offiziellen Formular für die <strong>Verordnung einer Krankenbeförderung</strong> ist es wichtig, dass das Kästchen neben 'KTW' (Krankentransportwagen) angekreuzt ist.
                    </Alert>
                    <img
                        src="/Muster_Trapo.jpg"
                        alt="Verordnung"
                        style={{ width: '100%' }}
                    />
                </Dialog>
            </>
        </TransportDesignBox>
    );
};

export default TransportDetailsStep;