import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../components/userContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import DateTimeHelper from 'ktw-shared';


const ConfigParamsList = () => {
    const [configParams, setConfigParams] = useState([]);
    const [filteredParams, setFilteredParams] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedParam, setEditedParam] = useState({ key: '', value: '', type: 'plain', description: '' });
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [newParam, setNewParam] = useState({ key: '', value: '', type: 'plain', description: '' });
    const [openAlert, setOpenAlert] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [paramToDelete, setParamToDelete] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [historyData, setHistoryData] = useState([]); // Historische Daten des Parameters
    const [openHistoryDialog, setOpenHistoryDialog] = useState(false); // Steuert das Modal
    const [selectedParamKey, setSelectedParamKey] = useState(null); // Key des ausgewählten Parameters

    useEffect(() => {
        if (user) {
            fetchConfigParams();
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        const filtered = configParams.filter(param => {
            const key = param.key || ''; // Fallback auf leeren String, falls key null ist
            const value = param.value || ''; // Fallback auf leeren String, falls value null ist
            const description = param.description || ''; // Fallback auf leeren String, falls description null ist

            return (
                key.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.toLowerCase().includes(searchTerm.toLowerCase()) ||
                description.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setFilteredParams(filtered);
    }, [searchTerm, configParams]);

    const fetchConfigParams = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/config/full`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (response.ok) {
                const data = await response.json();
                const formattedData = data.map((row) => ({
                    id: row.key,
                    key: row.key,
                    value: row.value,
                    type: row.type,
                    description: row.description,
                }));
                setConfigParams(formattedData);
                setFilteredParams(formattedData);
                setServerError(null);
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Parameter:', error.message);
            setServerError('Fehler beim Abrufen der Parameter.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchHistory = async (key) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/config/${key}/history`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setHistoryData(data);
                setServerError(null);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Abrufen der Historie.');
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Historie:', error.message);
            setServerError('Fehler beim Abrufen der Historie.');
        }
    };

    const handleHistoryClick = async (key) => {
        setSelectedParamKey(key);
        await fetchHistory(key);
        setOpenHistoryDialog(true);
    };

    const handleAddNewParam = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/config`, {
                method: 'POST',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newParam),
            });

            if (response.ok) {
                setNewParam({ key: '', value: '', type: 'plain', description: '' });
                fetchConfigParams();
                setServerError(null);
                setOpenAlert(true);
                setTimeout(() => setOpenAlert(false), 2000);
                setOpenEditDialog(false);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Hinzufügen des Parameters.');
            }
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Parameters:', error.message);
            setServerError('Fehler beim Hinzufügen des Parameters.');
        }
    };

    const handleSaveEdit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/config/${editedParam.key}`, {
                method: 'PUT',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedParam),
            });

            if (response.ok) {
                setIsEditMode(false);
                fetchConfigParams();
                setServerError(null);
                setOpenEditDialog(false);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Aktualisieren des Parameters.');
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Parameters:', error.message);
        }
    };

    const handleDeleteParam = async (key) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/config/${key}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (response.ok) {
                fetchConfigParams();
                setServerError(null);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Löschen des Parameters.');
            }
        } catch (error) {
            console.error('Fehler beim Löschen des Parameters:', error.message);
            setServerError('Fehler beim Löschen des Parameters: ' + error.message);
        }
    };

    const handleDeleteClick = (key) => {
        setParamToDelete(key);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (paramToDelete) {
            await handleDeleteParam(paramToDelete);
            setParamToDelete(null);
        }
        setDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setParamToDelete(null);
        setDeleteDialogOpen(false);
    };

    const handleEditParam = (key) => {
        const paramToEdit = configParams.find((param) => param.key === key);
        setEditedParam({ ...paramToEdit });
        setIsEditMode(true);
        setOpenEditDialog(true);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const columns = [
        { field: 'key', headerName: 'Schlüssel', width: 280 },
        {
            field: 'value',
            headerName: 'Wert',
            width: 320,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'normal' }}>
                    <Typography variant="body2">
                        {params.row.type === 'encrypted' ? '****' : params.row.value}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'description',
            headerName: 'Beschreibung',
            width: 600,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal' }}>
                    <Typography variant="body2">
                        {params.row.description}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 120,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Löschen"
                    showInMenu
                    onClick={() => handleDeleteClick(params.row.key)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Bearbeiten"
                    onClick={() => handleEditParam(params.row.key)}
                />,
                <GridActionsCellItem
                    icon={<HistoryIcon />} // Zum Beispiel ein Uhren-Symbol
                    label="Historie"
                    showInMenu
                    onClick={() => handleHistoryClick(params.row.key)}
                />,
            ],
        },
    ];

    return (
        <Box m={1}>
            <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                Konfigurationsparameter
            </Typography>
            {isLoading && <CircularProgress color="inherit" />}
            {!isLoading && (
                <div>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <TextField
                            label="Suchen"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{ width: '80%' }} // Breite der Suchmaske angepasst
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setNewParam({ key: '', value: '', type: 'plain', description: '' });
                                setIsEditMode(false);
                                setOpenEditDialog(true);
                            }}
                        >
                            Hinzufügen
                        </Button>
                    </Box>

                    {serverError && <Alert severity="error">{serverError}</Alert>}
                    {openAlert && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Parameter erfolgreich hinzugefügt.
                        </Alert>
                    )}
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={filteredParams}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.key}
                        />
                    </div>
                </div>
            )}

            <Dialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                aria-labelledby="edit-dialog-title"
                maxWidth="md" // Dialog breiter machen
                fullWidth // Dialog nimmt die maximale Breite ein
            >
                <DialogTitle id="edit-dialog-title">
                    {isEditMode ? 'Parameter bearbeiten' : 'Neuen Parameter hinzufügen'}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Schlüssel"
                        variant="outlined"
                        fullWidth
                        value={isEditMode ? editedParam.key : newParam.key}
                        onChange={(e) =>
                            isEditMode
                                ? setEditedParam({ ...editedParam, key: e.target.value })
                                : setNewParam({ ...newParam, key: e.target.value })
                        }
                        sx={{ mb: 2, mt: 2 }}
                    />
                    {(isEditMode
                        ? editedParam.value === 'true' || editedParam.value === 'false'
                        : newParam.value === 'true' || newParam.value === 'false') ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Wert</InputLabel>
                            <Select
                                value={isEditMode ? editedParam.value : newParam.value}
                                label="Wert"
                                onChange={(e) =>
                                    isEditMode
                                        ? setEditedParam({ ...editedParam, value: e.target.value })
                                        : setNewParam({ ...newParam, value: e.target.value })
                                }
                            >
                                <MenuItem value="true">True</MenuItem>
                                <MenuItem value="false">False</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            label="Wert"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={isEditMode ? editedParam.value : newParam.value}
                            onChange={(e) =>
                                isEditMode
                                    ? setEditedParam({ ...editedParam, value: e.target.value })
                                    : setNewParam({ ...newParam, value: e.target.value })
                            }
                            sx={{ mb: 2 }}
                        />
                    )}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Typ</InputLabel>
                        <Select
                            value={isEditMode ? editedParam.type : newParam.type}
                            label="Typ"
                            onChange={(e) => {
                                const newType = e.target.value;
                                if (isEditMode) {
                                    setEditedParam({ ...editedParam, type: newType });
                                } else {
                                    setNewParam({ ...newParam, type: newType });
                                }
                            }}
                        >
                            <MenuItem value="plain">Plain</MenuItem>
                            <MenuItem value="encrypted">Encrypted</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Beschreibung"
                        variant="outlined"
                        fullWidth
                        value={isEditMode ? editedParam.description : newParam.description}
                        onChange={(e) =>
                            isEditMode
                                ? setEditedParam({ ...editedParam, description: e.target.value })
                                : setNewParam({ ...newParam, description: e.target.value })
                        }
                        sx={{ mb: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="secondary">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={isEditMode ? handleSaveEdit : handleAddNewParam}
                        color="primary"
                    >
                        {isEditMode ? 'Speichern' : 'Hinzufügen'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Parameter löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Möchten Sie den Parameter "{paramToDelete}" wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openHistoryDialog}
                onClose={() => setOpenHistoryDialog(false)}
                aria-labelledby="history-dialog-title"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="history-dialog-title">Änderungshistorie für: {selectedParamKey}</DialogTitle>
                <DialogContent>
                    {historyData.length === 0 ? (
                        <Typography variant="body2">Keine Historie vorhanden.</Typography>
                    ) : (
                        <Box>
                            <Typography variant="h6">Änderungsprotokoll</Typography>
                            <div style={{ width: '100%', marginTop: '16px' }}>
                                <DataGrid
                                    rows={historyData.map((row, index) => ({
                                        ...row,
                                        id: index,
                                        modified_date: DateTimeHelper.formatDate(row.modified_date), // Formatieren des Datums
                                    }))}
                                    columns={[
                                        { field: 'old_value', headerName: 'Alter Wert', flex: 1 },
                                        { field: 'new_value', headerName: 'Neuer Wert', flex: 1 },
                                        { field: 'mod_user', headerName: 'Benutzer', width: 150 },
                                        { field: 'modified_date', headerName: 'Änderungsdatum', width: 200 },
                                        { field: 'description', headerName: 'Beschreibung', flex: 1 },
                                    ]}
                                    pageSize={5}
                                />
                            </div>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenHistoryDialog(false)} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ConfigParamsList;