// utils/whenStepHelpers.js
import dayjs from 'dayjs';

/**
 * Berechnet das Zeitfenster basierend auf den Time-Settings.
 * @param {string} dateTime - Der Zeitstempel im ISO-Format.
 * @param {string} transportType - Der Transporttyp (z. B. "TERMINFAHRT").
 * @param {object} timeSettings - Die Time-Settings-Konfiguration.
 * @returns {object|null} Ein Objekt mit `start` und `end` im Format "HH:mm" oder null, falls ungültig.
 */
export const calculateTimeWindow = (dateTime, transportType, timeSettings) => {
    if (!dateTime || !transportType || !timeSettings?.settings) return null;

    let settingKey = transportType;
    if (transportType === 'DIALYSEFAHRT') {
        settingKey = 'DIALYSE_HINFART';
    }

    const settings = timeSettings.settings[settingKey];
    if (!settings) {
        console.warn(`calculateTimeWindow: No settings found for transportType: ${transportType}`);
        return null;
    }

    const selectedTime = dayjs(dateTime);
    if (!selectedTime.isValid()) {
        //console.error('calculateTimeWindow: Invalid dateTime format');
        return null;
    }

    // Überprüfen, ob minusTime und plusTime definiert sind
    if (!settings.minusTime && !settings.plusTime) {
        // console.info('Abbruch: MinusTime und PlusTime sind entweder null oder leer.');
        return null;
    }

    const windowStart = settings.minusTime
        ? selectedTime.subtract(Number(settings.minusTime.split(':')[0]), 'hour')
            .subtract(Number(settings.minusTime.split(':')[1]), 'minute')
        : selectedTime;

    const windowEnd = settings.plusTime
        ? selectedTime.add(Number(settings.plusTime.split(':')[0]), 'hour')
            .add(Number(settings.plusTime.split(':')[1]), 'minute')
        : selectedTime;

    return {
        start: windowStart.format('HH:mm'),
        end: windowEnd.format('HH:mm')
    };
};

/**
 * Generiert einen Text, der das Zeitfenster für den Transport beschreibt.
 * @param {string} dateTime - Der Zeitstempel im ISO-Format.
 * @param {string} transportType - Der Transporttyp (z. B. "TERMINFAHRT").
 * @param {object} timeSettings - Die Time-Settings-Konfiguration.
 * @returns {string|null} Ein beschreibender Text oder null, falls ungültig.
 */
export const getTimeRangeText = (dateTime, transportType, timeSettings) => {
    if (!dateTime || !transportType || !timeSettings?.settings) return null;

    const window = calculateTimeWindow(dateTime, transportType, timeSettings);
    if (!window) return null;

    return (
        <>
            Ihr Transport am <strong>{dayjs(dateTime).format('DD.MM.YYYY')}</strong> ({transportType}) wird für den Zeitraum{' '}
            <strong>{window.start} bis {window.end} Uhr</strong> gebucht.
            <br />
            Die genaue Ankunftszeit innerhalb dieses Zeitfensters wird in Ihrer Bestellübersicht angezeigt und automatisch aktualisiert, sobald Ihr Auftrag eingeplant wurde.
        </>
    );
};

export const getTimeRangeInfoText = (dateTime, transportType, timeSettings) => {
    if (!transportType || !timeSettings?.settings) return null;

    let settingKey = transportType;
    if (transportType === 'DIALYSEFAHRT') {
        settingKey = 'DIALYSE_HINFART';
    }

    const settings = timeSettings.settings[settingKey];
    if (!settings) return null;

    // Statisches Zeitfenster aus den Settings
    const staticTimeText = settings.startTime && settings.endTime
        ? `Eine (${transportType}) kann in der Zeit von ${settings.startTime}-${settings.endTime} Uhr bestellt werden.`
        : '';

    return staticTimeText;
};

