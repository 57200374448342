import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    const [settings, setSettings] = useState(() => {
        const savedSettings = localStorage.getItem('settings');
        return savedSettings ? JSON.parse(savedSettings) : {};
    });

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            const savedSettings = localStorage.getItem('settings');
            if (savedSettings) {
                setSettings(JSON.parse(savedSettings));
            } else {
                const fetchSettings = async () => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/user-settings`, {
                            headers: { Authorization: user.api_key }
                        });
                        if (response.ok) {
                            const data = await response.json();
                            setSettings(data.settings || {});
                            localStorage.setItem('settings', JSON.stringify(data.settings || {}));
                        }
                    } catch (error) {
                        console.error('Fehler beim Abrufen der Benutzereinstellungen:', error.message);
                    }
                };
                fetchSettings();
            }
        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('settings');
            setSettings({});
        }
    }, [user]);

    const updateSetting = async (key, value) => {
        const updatedSettings = { ...settings, [key]: value };
        setSettings(updatedSettings);
        localStorage.setItem('settings', JSON.stringify(updatedSettings));

        try {
            await fetch(`${process.env.REACT_APP_API_URI}/api/user-settings`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: user.api_key },
                body: JSON.stringify({ settings: updatedSettings })
            });
        } catch (error) {
            console.error('Fehler beim Speichern der Benutzereinstellungen:', error.message);
        }
    };

    // logout mit useCallback memoizen
    const logout = useCallback(() => {
        setUser(null);
        setSettings({});
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('settings');
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, logout, settings, updateSetting }}>
            {children}
        </UserContext.Provider>
    );
};