import React from 'react';
import { Typography } from '@mui/material';

const AddressCell = ({ address }) => {
    const { institution, station, street, houseNumber, postalCode, city } = address;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: '4px', marginBottom: '4px' }}>
            {/* Institution: Fett */}
            {institution && (
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {institution}
                </Typography>
            )}

            {/* Station: Normal (falls vorhanden) */}
            {station && (
                <Typography variant="body2">
                    {station}
                </Typography>
            )}

            {/* Straße und Hausnummer: Grau kursiv */}
            {street && houseNumber && (
                <Typography variant="body2" style={{ fontStyle: 'italic', color: 'gray' }}>
                    {`${street} ${houseNumber}`}
                </Typography>
            )}

            {/* PLZ und Stadt: Grau kursiv */}
            {postalCode && city && (
                <Typography variant="body2" style={{ fontStyle: 'italic', color: 'gray' }}>
                    {`${postalCode} ${city}`}
                </Typography>
            )}
        </div>
    );
};

export default AddressCell;