//validationLocic.js
import dayjs from 'dayjs';


/**
 * Validiert Transportzeiten basierend auf den Time-Settings und Benutzerrolle
 * @param {string} dateTime - Zu validierender Zeitstempel
 * @param {string} transportType - Transporttyp
 * @param {object} timeSettings - Time-Settings Konfiguration
 * @param {object} user - Benutzerobjekt
 * @returns {string|null} Fehlermeldung oder null
 */
export const validateTransportTime = (dateTime, transportType, timeSettings, user, isReturn = false) => {
    if (!dateTime || !transportType || !timeSettings?.settings) return null;

    // Nur für bestimmte Rollen validieren
    if (['poweruser', 'admin'].includes(user?.role)) return null;

    let settingKey = transportType;
    if (transportType === 'DIALYSEFAHRT') {
        settingKey = isReturn ? 'DIALYSE_RUECKFART' : 'DIALYSE_HINFART';
    }
    const settings = timeSettings.settings[settingKey];
    if (!settings) return null;

    const selectedTime = dayjs(dateTime);
    const currentTime = dayjs();

    // 1. Vergangenheitscheck für alle Rollen
    if (selectedTime.isBefore(currentTime, 'minute')) {
        return 'Buchungen in der Vergangenheit sind nicht möglich';
    }

    // 2. Zeitspanne Validierung
    const timeStr = selectedTime.format('HH:mm');
    if (settings.startTime && settings.endTime &&
        (timeStr < settings.startTime || timeStr > settings.endTime)) {
        return `Buchungen sind nur zwischen ${settings.startTime} und ${settings.endTime} Uhr möglich`;
    }

    // 3. Buchungslogik Validierung
    switch (settings.bookingLogic) {
        case 'sameDay':
            if (selectedTime.isBefore(currentTime, 'day')) {
                return 'Bestellungen sind nur für heute oder zukünftige Tage möglich';
            }
            break;

        case 'noRestriction':
            if (selectedTime.isSame(currentTime, 'day')) {
                return 'Bestellungen sind erst ab morgen möglich';
            }
            break;

        case '24hoursBefore':
            if (selectedTime.isBefore(currentTime.add(24, 'hour'))) {
                return 'Bestellungen müssen mindestens 24 Stunden im Voraus erfolgen';
            }
            break;

        case 'dayBeforeUntil':
            if (settings.hoursBefore) {
                const minTime = currentTime.add(Number(settings.hoursBefore), 'hour');
                if (selectedTime.isBefore(minTime)) {
                    return `Bestellungen müssen mindestens ${settings.hoursBefore} Stunden im Voraus erfolgen`;
                }
            }
            break;

        case 'previousDayUntil':
            if (settings.previousDayTime) {
                const deadline = dayjs(selectedTime).subtract(1, 'day')
                    .set('hour', ...settings.previousDayTime.split(':').map(Number));
                if (currentTime.isAfter(deadline)) {
                    return `Bestellungen müssen bis ${settings.previousDayTime} Uhr am Vortag erfolgen`;
                }
            }
            break;

        case 'previousWorkDayUntil':
            if (settings.previousWorkDayTime) {
                let previousWorkDay = selectedTime.subtract(1, 'day');
                while (previousWorkDay.day() === 0 || previousWorkDay.day() === 6) {
                    previousWorkDay = previousWorkDay.subtract(1, 'day');
                }
                const deadline = previousWorkDay.set(
                    'hour', ...settings.previousWorkDayTime.split(':').map(Number)
                );
                if (currentTime.isAfter(deadline)) {
                    return `Bestellungen müssen bis ${settings.previousWorkDayTime} Uhr am vorherigen Werktag erfolgen`;
                }
            }
            break;

        default:
            break;
    }

    return null;
};

/**
 * Validiert die Zeit für einen bestimmten Tag basierend auf den Time-Slot-Einstellungen.
 * @param {string} time - Die zu validierende Zeit im Format "HH:mm".
 * @param {string} transportType - Der Transporttyp (z. B. "TERMINFAHRT", "DIALYSEFAHRT").
 * @param {object} timeSettings - Die Time-Slot-Einstellungen.
 * @param {boolean} isReturn - Gibt an, ob es sich um eine Rückfahrt handelt.
 * @returns {string|null} Fehlermeldung oder null, wenn die Zeit gültig ist.
 */
export const validateTimeSlot = (time, transportType, timeSettings, isReturn = false) => {
    if (!time || !transportType || !timeSettings) return null;

    let settingKey = transportType;
    if (transportType === 'DIALYSEFAHRT') {
        settingKey = isReturn ? 'DIALYSE_RUECKFART' : 'DIALYSE_HINFART';
    }

    const settings = timeSettings?.settings?.[settingKey] || {};
    const minTime = settings.startTime ? dayjs(settings.startTime, 'HH:mm') : null;
    const maxTime = settings.endTime ? dayjs(settings.endTime, 'HH:mm') : null;
    const trimmedTime = time.trim();
    const timeObj = dayjs(trimmedTime, 'HH:mm');

    // Überprüfen, ob die Zeit korrekt geparst wurde
    if (!timeObj.isValid()) {
        //console.error('Ungültige Zeit:', time);
        return null;
    }

    //console.log('validateTimeSlot', time, transportType, timeSettings, isReturn, minTime, maxTime, timeObj);
    if (minTime && timeObj.isBefore(minTime)) {
        return `Zeit muss nach ${minTime.format('HH:mm')} liegen`;
    }

    if (maxTime && timeObj.isAfter(maxTime)) {
        return `Zeit muss vor ${maxTime.format('HH:mm')} liegen`;
    }

    return null;
};

/**
 * Validiert die wiederkehrenden Zeiten für Hin- und Rückfahrten.
 * @param {object} formData - Die Formulardaten.
 * @param {object} timeSettings - Die Time-Slot-Einstellungen.
 * @returns {string[]} Liste von Fehlermeldungen.
 */
export const validateRecurringTimes = (formData, timeSettings) => {
    const errors = [];

    if (formData.is_recurring) {
        for (const [day, isSelected] of Object.entries(formData.recurring_days || {})) {
            if (isSelected) {
                const time = formData.recurring_times?.[day];
                if (!time || !time.trim()) {
                    errors.push(`⚠ Bitte geben Sie eine Zeit für den ausgewählten Tag ${day} (Hinfahrt) an.`);
                } else {
                    const validationError = validateTimeSlot(time, formData.transport_type, timeSettings, false);
                    if (validationError) {
                        errors.push(`⚠ ${day} (Hinfahrt): ${validationError}`);
                    }
                }
            }
        }
    }
    if (formData.is_recurring_return) {
        for (const [day, isSelected] of Object.entries(formData.recurring_return_days || {})) {
            if (isSelected) {
                const time = formData.recurring_return_times?.[day];
                if (!time || !time.trim()) {
                    errors.push(`⚠ Bitte geben Sie eine Zeit für den ausgewählten Tag ${day} (Rückfahrt) an.`);
                } else {
                    const validationError = validateTimeSlot(time, formData.transport_type, timeSettings, true);
                    if (validationError) {
                        errors.push(`⚠ ${day} (Rückfahrt): ${validationError}`);
                    }
                }
            }
        }
    }

    return errors;
};

/**
 * Validiert die Eingaben eines bestimmten Schritts und gibt Fehler zurück.
 * @param {number} step - Der Schritt, der validiert wird.
 * @param {object} formData - Die aktuellen Formulardaten.
 * @param {object} timeSettings - Die Time-Slot-Einstellungen.
 * @param {boolean} isEditMode - Gibt an, ob der Bearbeitungsmodus aktiv ist.
 * @returns {string[]} Liste von Fehlermeldungen.
 */
export const getValidationErrors = (step, formData, timeSettings, isEditMode, user) => {
    const errors = [];

    if (step === 0) {
        const phoneNumberRegex = /^\+?[0-9\s]*$/;

        if (!formData.phoneNumber) {
            errors.push('⚠ **Rückrufnummer** ist erforderlich.');
        } else if (!phoneNumberRegex.test(formData.phoneNumber)) {
            errors.push('⚠ **Telefonnummer** enthält ungültige Zeichen.');
        }

        if (formData.is_private) {
            if (!formData.private_first_name) {
                errors.push('⚠ **Vorname** ist erforderlich.');
            }
            if (!formData.private_last_name) {
                errors.push('⚠ **Nachname** ist erforderlich.');
            }
        } else {
            if (!formData.objectInstitution) {
                errors.push('⚠ **Institution** ist erforderlich.');
            }
            if (!formData.stationName) {
                errors.push('⚠ **Station** ist erforderlich.');
            }
        }
    }

    if (step === 1) {
        const isDateValid = dayjs(formData.dateOfBirth, 'DD.MM.YYYY', true).isValid();
        const isDateInFuture = dayjs(formData.dateOfBirth, 'DD.MM.YYYY').isAfter(dayjs().add(1, 'day'));
        const isDateInPast = dayjs(formData.dateOfBirth, 'DD.MM.YYYY').isBefore(dayjs().subtract(120, 'years'));

        if (!formData.firstName || !formData.lastName || !isDateValid || isDateInFuture || isDateInPast) {
            errors.push('⚠ Die **Patientendaten** sind nicht vollständig oder ungültig. Bitte überprüfen Sie Vorname, Nachname und Geburtsdatum.');
        }

        if (formData.infectious === true && !formData.infektion_id) {
            errors.push('⚠ **Infektions-ID** ist erforderlich, wenn der Patient infektiös ist.');
        }
        if (formData.weightChecked !== true) {
            errors.push('⚠ **Gewicht** muss bestätigt werden.');
        }
    }

    if (step === 2) {
        if (!formData.transport_type) {
            errors.push('⚠ **Transportart** ist erforderlich.');
        }

        // Fall 1: Einzelne Fahrten (keine wiederkehrenden Fahrten)
        if (!formData.is_recurring && !formData.is_recurring_return) {
            if (!formData.initial_trip && !formData.return_trip) {
                errors.push('⚠ Es muss entweder eine **Hinfahrt** oder eine **Rückfahrt** ausgewählt sein.');
            }

            if (formData.initial_trip || isEditMode) {
                if (!formData.departureDateTime) {
                    errors.push('⚠ **Abfahrtsdatum** für die **Hinfahrt** ist erforderlich.');
                } else {
                    let isDepartureDateTimeValid = dayjs(formData.departureDateTime).isAfter(dayjs().add(1, 'hour'));
                    let errorMessage = '⚠ **Abfahrtsdatum** für die **Hinfahrt** muss mindestens eine Stunde in der Zukunft liegen.';

                    if (['poweruser', 'admin'].includes(user?.role)) {
                        isDepartureDateTimeValid = dayjs(formData.departureDateTime).isAfter(dayjs().add(-1, 'minute'));
                        errorMessage = '⚠ **Abfahrtsdatum** für die **Hinfahrt** muss mindestens eine Minute in der Zukunft liegen.';
                    }

                    const isDepartureDateTimeInFarFuture = dayjs(formData.departureDateTime).isAfter(dayjs().add(10, 'years'));

                    if (!isDepartureDateTimeValid) {
                        errors.push(errorMessage);
                    }
                    if (isDepartureDateTimeInFarFuture) {
                        errors.push('⚠ **Abfahrtsdatum** für die **Hinfahrt** darf nicht mehr als 10 Jahre in der Zukunft liegen.');
                    }
                }
            }

            if (formData.return_trip && !isEditMode) {
                if (!formData.returnDepartureTime) {
                    errors.push('⚠ **Abfahrtsdatum** für die **Rückfahrt** ist erforderlich.');
                } else {
                    let isReturnDepartureTimeValid = dayjs(formData.returnDepartureTime).isAfter(dayjs().add(1, 'hour'));
                    let errorMessage = '⚠ **Abfahrtsdatum** für die **Rückfahrt** muss mindestens eine Stunde in der Zukunft liegen.';

                    if (['poweruser', 'admin'].includes(user?.role)) {
                        isReturnDepartureTimeValid = dayjs(formData.returnDepartureTime).isAfter(dayjs().add(-1, 'minute'));
                        errorMessage = '⚠ **Abfahrtsdatum** für die **Rückfahrt** muss mindestens eine Minute in der Zukunft liegen.';
                    }

                    if (!isReturnDepartureTimeValid) {
                        errors.push(errorMessage);
                    }
                }
            }
        }

        // Fall 2: Regelmäßige Fahrten (wiederkehrende Fahrten)
        if (formData.is_recurring || formData.is_recurring_return) {
            const hasRecurringDays = formData.recurring_days && Object.values(formData.recurring_days).some(isSelected => isSelected === true);
            const hasRecurringReturnDays = formData.recurring_return_days && Object.values(formData.recurring_return_days).some(isSelected => isSelected === true);

            if (!hasRecurringDays && !hasRecurringReturnDays) {
                errors.push('⚠ Bitte wählen Sie mindestens **einen Tag** für die **Hin-** oder **Rückfahrt** aus.');
            } else {
                // Validierung der wiederkehrenden Zeiten
                const recurringTimeErrors = validateRecurringTimes(formData, timeSettings);
                errors.push(...recurringTimeErrors);
            }
        }

        if (formData.transport_type === 'DIALYSEFAHRT' || formData.transport_type === 'TERMINFAHRT') {
            if (!formData.is_recurring && !formData.is_recurring_return && !formData.return_trip && !formData.initial_trip) {
                errors.push('⚠ Für **Dialyse- oder Terminfahrten** muss entweder eine **wiederkehrende Fahrt** oder eine **Hin-** oder **Rückfahrt** angegeben werden.');
            }
        }

        // Fall 3 Transportzeit-Validierung für bestimmte Rollen gemäß der time-slot Einstellungen
        if (formData.departureDateTime && formData.transport_type && !formData.isHeadOfSeries) {
            const timeError = validateTransportTime(
                formData.departureDateTime,
                formData.transport_type,
                timeSettings,
                user, // User muss an die Funktion übergeben werden,
                formData.isHeadOfSeries, // Überprüfen, ob der Benutzer der "Kopf der Serie" ist
            );

            if (timeError) {
                errors.push(`⚠ ${timeError}`);
            }
        }
    }

    if (step === 3) {
        if (!formData.from_street || !formData.from_house_number || !formData.from_postal_code || !formData.from_city) {
            errors.push('⚠ Die **Startadresse** ist nicht vollständig. Bitte überprüfen Sie Straße, Hausnummer, Postleitzahl und Stadt.');
        }

        if (!formData.to_street || !formData.to_house_number || !formData.to_postal_code || !formData.to_city) {
            errors.push('⚠ Die **Zieladresse** ist nicht vollständig. Bitte überprüfen Sie Straße, Hausnummer, Postleitzahl und Stadt.');
        }
    }

    if (step === 4) {
        if (!formData.transport_mode) {
            errors.push('⚠ **Transportart** ist erforderlich.');
        }

        if (!formData.hasOwnProperty('insurance_approved')) {
            errors.push('⚠ Angebaben zur Genehmigung der **Krankenkasse** sind erforderlich.');
        }
        if (formData.insurance_approved === false && !formData.confirm_insurance_approved) {
            errors.push('⚠ Bestätigung erforderlich, dass der **Transport privat gezahlt** werden muss.');
        }

        if (!formData.hasOwnProperty('own_wheelchair')) {
            errors.push('⚠ Angaben zum **Rollstuhl** sind erforderlich.');
        }
        if (formData.own_wheelchair === true) {
            if (!formData.hasOwnProperty('folding')) {
                errors.push('⚠ Bitte geben Sie an, ob der **Rollstuhl** klappbar ist.');
            }
            if (formData.folding === false && !formData.confirm_folding) {
                errors.push('⚠ Bestätigung erforderlich, dass der **Rollstuhl** nicht **klappbar** ist.');
            }
        }
        if (!formData.hasOwnProperty('luggage')) {
            errors.push('⚠ Angaben zum **Gepäck** sind erforderlich.');
        }
        if (formData.luggage === true && !formData.confirm_luggage) {
            errors.push('⚠ Bestätigung erforderlich, dass maximal ein **Gepäckstück** mitgenommen werden kann.');
        }

        if (!formData.hasOwnProperty('companion')) {
            errors.push('⚠ Angaben zur **Begleitperson** sind erforderlich.');
        }
        if (formData.companion === true && !formData.confirm_companion) {
            errors.push('⚠ Bestätigung erforderlich, dass eine **Begleitperson dabei** ist.');
        }

        if (!formData.hasOwnProperty('oxygen_required')) {
            errors.push('⚠ Angaben zum **Sauerstoff** sind erforderlich.');
        }
        if (formData.oxygen_required === true && !formData.oxygen_source) {
            errors.push('⚠ **Sauerstoffquelle** ist erforderlich, wenn Sauerstoff benötigt wird.');
        }

        if (formData.self_payer !== true) {
            if (!formData.hasOwnProperty('prescription_present')) {
                errors.push('⚠ Angaben zur **Verordnung** sind erforderlich.');
            }

            if (formData.prescription_present === false && !formData.confirm_prescription_present) {
                errors.push('⚠ Bestätigung erforderlich, dass **keine Verordnung** vorliegt.');
            }
            if (formData.insurance_approved === false && !formData.confirm_insurance_approved) {
                errors.push('⚠ Bestätigung erforderlich, dass die **Krankenkasse** den Transport **genehmigt** hat.');
            }
        }
    }

    return errors;
};